@use "../utils/" as *;

.header {
  position: fixed;
  @include flex($justify-content: space-between);
  font-size: rem(18);
  padding: 0 rem(36);
  width: 100vw;
  z-index: 10;
  background: rgb(255 255 255 / 50%);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);

  &__nav {
    flex: 1;

    a {
      display: inline-block;
      position: relative;
      padding: rem(18) rem(8);
      margin-right: rem(16);

      color: var(--col-primary);
      transition: all 0.3s;

      &:hover,
      &:focus,
      &.active {
        color: var(--col-txt-bg);
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: var(--col-txt-bg);
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out;
      }

      &:hover::after {
        transform: scaleX(1);
      }

      &.active::after {
        transform: scaleX(1);
      }
    }
  }
}
