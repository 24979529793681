*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  font-family: var(--font-inter);
  background-color: var(--col-bg);
  color: var(--col-txt-bg);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='72' viewBox='0 0 36 72'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%231b9999' fill-opacity='0.3'%3E%3Cpath d='M2 6h12L8 18 2 6zm18 36h12l-6 12-6-12z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.u-mt-large {
  margin-top: 36px !important;
}

.u-mt-small {
  margin-top: 12px !important;
}

.u-mt-tiny {
  margin-top: 6px !important;
}

.u-text-subheading {
  font-size: 0.875rem;
  font-weight: 700 !important;
  color: var(--col-txt1) !important;
}

.u-divider {
  width: 100%;
  height: 1px;
  margin: 12px 0;
  background-color: var(--col-bg-card);
}
@media (min-width: 40em) {
  .u-divider {
    display: none;
  }
}

.span-all {
  grid-column: 1/-1;
}

:root {
  --col-primary: #4ea853;
  --col-secondary: #0d81b5;
  --col-txt-primary: rgb(250, 250, 250);
  --col-txt-bg: rgb(66, 66, 66);
  --col-txt-bg-alt: rgba(66, 66, 66, 0.7);
  --col-bg: rgb(255, 255, 255);
  --box-shadow: 0 5px 20px 1px rgba(0, 0, 0, 0.5);
}

[data-theme=dark] {
  --col-primary: #41b35d;
  --col-secondary: #f9a826;
  --col-txt-primary: #000;
  --col-txt-bg: rgba(255, 255, 255, 0.9);
  --col-txt-bg-alt: rgba(255, 255, 255, 0.7);
  --col-bg: #121212;
}

:root {
  --font-inter: "Inter", sans-serif;
}

body {
  font-family: var(--font-inter);
}

h1,
h2,
h3 {
  margin-top: 0;
}

h1 {
  font-size: 3.75rem;
  font-family: "Teko", sans-serif;
  color: var(--col-txt-bg);
}
@media (max-width: 71.8125em) {
  h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 39.9375em) {
  h1 {
    font-size: 2rem;
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.header {
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.125rem;
  padding: 0 2.25rem;
  width: 100vw;
  z-index: 10;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
}
.header__nav {
  flex: 1;
}
.header__nav a {
  display: inline-block;
  position: relative;
  padding: 1.125rem 0.5rem;
  margin-right: 1rem;
  color: var(--col-primary);
  transition: all 0.3s;
}
.header__nav a:hover, .header__nav a:focus, .header__nav a.active {
  color: var(--col-txt-bg);
}
.header__nav a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: var(--col-txt-bg);
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.header__nav a:hover::after {
  transform: scaleX(1);
}
.header__nav a.active::after {
  transform: scaleX(1);
}

.section {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.875rem;
}

.home__heading {
  text-align: center;
}
.home p {
  margin-bottom: 1rem;
}
.home__btns {
  margin-top: 1.25rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 39.9375em) {
  .home__btns {
    flex-direction: column;
  }
}
.home__btn {
  display: inline-block;
  border: none;
  padding: 0.625rem 1rem;
  margin-right: 0.75rem;
  margin-bottom: 1rem;
  width: 180px;
  border-radius: 100px;
  cursor: pointer;
  transition: transform 0.3s;
}
.home__btn--solid {
  color: white;
  background-color: var(--col-primary);
}
.home__btn--outline {
  border: 2px solid var(--col-primary);
  background-color: white;
  color: var(--col-primary);
}
.home__btn:hover, .home__btn:focus {
  transform: scale(1.02);
}
.home__btn:active {
  transform: scale(0.98);
}

.about p {
  max-width: 450px;
}

.project {
  align-items: stretch;
}
.project__wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.875rem;
  justify-items: center;
}
.project__wrapper .illustration {
  width: 18.75rem;
}
.project__wrapper .illustration img {
  width: 100%;
}

.social-icons {
  max-width: 90%;
  margin: 0 auto;
}

.fab {
  font-size: 6.25rem;
  margin-right: 3.125rem;
  cursor: pointer;
  color: var(--col-primary);
}
@media (max-width: 71.8125em) {
  .fab {
    font-size: 5rem;
    margin-right: 1.875rem;
  }
}
@media (max-width: 39.9375em) {
  .fab {
    font-size: 3.125rem;
    margin-right: 0.625rem;
  }
}

.toggle {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.toggle__icon {
  margin-right: 0.75rem;
}
.toggle__text {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.toggle__switch {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 34px;
  border-radius: 50px;
  cursor: pointer;
  background: #ccc;
}
.toggle__switch::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.3s;
}
.toggle__input {
  display: none;
}
.toggle__input:checked + label {
  background-color: var(--col-primary);
}
.toggle__input:checked + label::after {
  transform: translateX(100%);
}