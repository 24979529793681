@use "utils" as *;

:root {
  --font-inter: "Inter", sans-serif;
}

body {
  font-family: var(--font-inter);
}

h1,
h2,
h3 {
  margin-top: 0;
}

h1 {
  font-size: rem(60);
  font-family: "Teko", sans-serif;
  color: var(--col-txt-bg);
  // line-height: 0;

  @include bp-down("medium") {
    font-size: rem(40);
  }

  @include bp-down("small") {
    font-size: rem(32);
  }
}

a,
a:visited,
a:active {
  text-decoration: none;
}
