@mixin flex(
  $direction: row,
  $justify-content: center,
  $align-items: center,
  $wrap: nowrap
) {
  display: flex;
  flex-wrap: $wrap;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--col-card-hover);
  }
}
